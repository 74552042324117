/* cascadia-code-latin-ext-400-normal*/
@font-face {
  font-family: 'Cascadia Code';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    url('./files/cascadia-code-latin-ext-400-normal.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./files/cascadia-code-latin-ext-400-normal.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
